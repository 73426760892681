export class Route {
  route_id : any;
  name: string;
  service_flag: number;
  destination_id: any;
  provider_id: number;
  destination: string;
  provider: string;
  // disable: string = null;
}
