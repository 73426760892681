import { saveAs } from 'file-saver';
import { Response } from '@angular/http';

/**
 * Saves a file by opening file-save-as dialog in the browser
 * using file-save library.
 * @param blobContent file content as a Blob
 * @param fileName name file should be saved as
*/

// export const saveFile = async (blobContent: Blob, fileName: string) => {
//   fileName = fileName.replace(/^"(.*)"$/, '$1');
//   const blob = new Blob([blobContent], { type: 'application/octet-stream' });
//   await saveAs(blob, fileName);
// };
export const saveFile = async (blobContent: Blob, fileName: string): Promise<void> => {
  fileName = fileName.replace(/^"(.*)"$/, '$1');
  const blob = new Blob([blobContent], { type: 'application/octet-stream' });
  return new Promise<void>((resolve, reject) => {
    try {
      saveAs(blob, fileName);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};


/**
 * Derives file name from the http response
 * by looking inside content-disposition
 * @param res http Response
*/
export const getFileNameFromResponseContentDisposition = (res: Response) => {
  const contentDisposition = res.headers.get('content-disposition') || '';
  const matches = /filename=([^;]+)/ig.exec(contentDisposition);
  let fileName = (matches[1] || 'untitled').trim();
  fileName = fileName.replace(/^_+|_+$/g, '');
  return fileName;
};
