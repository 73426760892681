
<nb-card>
  <nb-card-header>
    Forgot Password
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-sm-12">
        <form (ngSubmit)="requestPass()" #form="ngForm" aria-labelledby="title">
          <div class="form-control-group">
            <label class="label" for="input-email">Email address:</label>
            <input
              nbInput
              fullWidth
              [(ngModel)]="user.email"
              #email="ngModel"
              name="email"
              id="input-email"
              pattern=".+@.+\..+"
              placeholder="Email address"
              autofocus
              [required]="getConfigValue('forms.validation.email.required')"
            />

            <ng-container *ngIf="email.invalid && email.touched">
              <p class="caption status-danger" *ngIf="email.errors?.required">
                Email is required!
              </p>
              <p class="caption status-danger" *ngIf="email.errors?.pattern">
                Please enter a valid email address.
              </p>
            </ng-container>
          </div>
      <button
            nbButton
            fullWidth
            status="success"
            [disabled]="submitted || !form.valid"
            [class.btn-pulse]="submitted"
          >
           Submit
          </button>
        </form>
      </div>
    </div>
  </nb-card-body>
</nb-card>
