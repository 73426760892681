export class Provider {
  provider_id: number;
  name: string;
  gateway_flag: number;
  // service_flag: number;
  node_id: number;
  host: number;
  port: number;
  username: string;
  password: string;
  dialstring: string;
  prefix: number;
  settings: string;
  register: boolean;
  weight: number;
  type: any;
  active: any;
  tenant_id: number;
  assigned_to: any;
  httpkey: any;
  source: string;
  subType: string = 'clickatell';  // Default value
}
