import { Component, NgZone } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
})


export class SessionComponent {

  constructor(public activeModal: NgbActiveModal,    
    private ngZone: NgZone
  ) {}
  ngOnInit() {
    this.startAutoLogoutTimer();
  }
  autoLogoutTimer: any;


  stayLoggedIn(): void {
    this.activeModal.close(true); 
  }

  logout() {
    localStorage.clear()
    window.location.reload();
    this.activeModal.close(false); 
  }

  onCancelClick(): void {
    this.activeModal.close(false); 
  }
  startAutoLogoutTimer() {
    if (this.autoLogoutTimer) {
      clearTimeout(this.autoLogoutTimer);
    }
    this.autoLogoutTimer = setTimeout(() => {
      this.ngZone.run(() => this.logout());
    }, 60 * 1000);
  }
}
